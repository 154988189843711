// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseUrl: (window.location.hostname == 'd.somo.loan.pre' ? `http://d.somo.loan.api` : `https://api.loan.somoafrica.com`),
  baseUrl2: (window.location.hostname == 'd.somo.loan.pre' ? `http://d.somo.invest` : `https://invest.somoafrica.org`),
  baseUrl3: `https://session-engine.digikua.com`,
  baseUrl4: `https://digikua.com`,
  baseUrl5: `https://validation.digikua.co.ke`,
  // uploadUrl: (window.location.hostname=='d.somo.loan.pre' ? `http://d.somo.invest` : `https://invest.somoafrica.org`) + '/api/media/up/loan/?toGrive'
  uploadUrl: 'https://invest.somoafrica.org/api/media/up/loan/?toGrive',
  pmtCalcUrl: (window.location.hostname == 'd.somo.loan.pre' ? `http://d.somo.invest` : `https://invest.somoafrica.org`) + '/api/data/loan/calculate/',
  usrUnbox: '/user/unbox',
  addProposalUrl: '/client/loan/proposal/:user_id',
  getLoanState: '/client/loan/get_state/:user_id',
  addLoanFundUrl: '/client/loan/fund/:user_id',
  addLoanAmount: '/client/loan/amount/:user_id',
  getOnboardingState: '/client/onboarding/get_state/:user_id',
  getApplicantState: '/client/applicant/get_state/:user_id',
  uploadUrlOnboard: 'https://invest.somoafrica.org/api/media/up/onboard/?toGrive',
  addOnboardUrl: '/client/onboarding/set_state/:user_id',
  addApplicantUrl: '/client/applicant/set_state/:user_id',
  pdfSign: (window.location.hostname == 'd.somo.loan.pre' ? 'http://d.pdf.sign/?url=' : 'https://sign.somoafrica.com/?url='),
  getPairedAdvisors: '/api/playbook/v2/pairing/list',
  getPlaybookChapters: '/api/playbook/v2/chapter/list?playbookId={playbookId}',
  getPlaybookSessions: '/api/playbook/v2/session/list?chapterId={chapterId}',
  getPBookSesInfo: '/api/playbook/v2/session/list?id={id}',
  getPBookToolNCheck: '/api/playbook/v2/tool_checklist/list?sessionId={sessId}&pairingId={pairId}',
  getPBookTasks: '/api/playbook/v2/assignment/list?sessionId={sessId}&pairingId={pairId}',
  getPBookFeeds: '/api/playbook/v2/feedback/list?sessionId={sessId}&entrepreneurId={usrId}',
  addPBookFeed: '/api/loans/playbook/v2/add_feedback',
  addTaskResp: '/api/loans/playbook/v2/resp_assignment',
  getCurrentLoans: '/client/loan/get_current_loans/:user_id',
  initMpesaPayment: '/api/loans/loanfund/init_mpesa_repayment',
  surveyMenuRead: '/api/?res=menu&q=get&survey=q.pq',
  finInstitutes: '/api.php?resource=financialStatement&action=readFinancialInstitutions',
  uploadValidateStt: '/process-financial-statements'
}