import React, { useContext, useEffect, PureComponent, useState } from "react";
import styles from "./ScoreDetail.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import BusinessInfoCard from "../../components/BusinessInfoCard/BusinessInfoCard";
import ScoresTable from "../../components/ScoresTable/ScoresTable";
import ScoresAverage from "../../components/ScoresAverage/ScoresAverage";
import DownloadScores from "../../components/DownloadScores/DownloadScores";
import { FaArrowLeft } from "react-icons/fa";



import { useLocation, useHistory } from "react-router-dom";
import { clientRoutes } from "../../routes/sidebarRoutes";

const ScoreDetail = () => {
    const location = useLocation();
    const history = useHistory();

    const data = location.state?.data || {};

    const date = new Date(data.data[0].dateAdded * 1000);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const dateScored = `${year}-${month}-${day}`;

    const [scores, setScores] = useState();
    const filteredScores = [];
    const [rank, setRank] = useState("");


    useEffect(() => {
        const thisScoreData = {
            year: data.data[0].year,
            quarter: data.data[0].quarter
        };

        const requestOptions = {
            method: 'POST',
            headers: {},
            body: JSON.stringify(thisScoreData),
        };

        fetch('https://space.somoafrica.org/api.php?resource=scorecard&action=read', requestOptions)
            .then((response) => response.json())
            .then((allScoresData) => {
                setScores(allScoresData);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [data]);

    useEffect(() => {
        function findRankByBusinessId(businessId) {
            if (!scores?.data) {
                console.error('Error: Data not available yet.');
                return null;
            }

            const businessData = scores.data.find((entry) => entry.business.id === businessId);
            const month = businessData.business.months;

            if (!businessData) {
                console.error('Error: Business with the given businessId not found in the data.');
                return null;
            }

            const specificBusinessAverageScore = parseFloat(businessData.averageScore);

            if (month >= 1 && month <= 6) {
                for (let index = 0; index < scores.data.length; index++) {
                    if (scores.data[index].business.months >= 1 && scores.data[index].business.months <= 6) {
                        filteredScores.push(scores.data[index])
                    }
                }
            } else if (month >= 7 && month <= 12) {
                for (let index = 0; index < scores.data.length; index++) {
                    if (scores.data[index].business.months >= 7 && scores.data[index].business.months <= 12) {
                        filteredScores.push(scores.data[index])
                    }
                }
            } else if (month >= 13 && month <= 24) {
                for (let index = 0; index < scores.data.length; index++) {
                    if (scores.data[index].business.months >= 13 && scores.data[index].business.months <= 24) {
                        filteredScores.push(scores.data[index])
                    }
                }
            } else if (month >= 25) {
                for (let index = 0; index < scores.data.length; index++) {
                    if (scores.data[index].business.months >= 25) {
                        filteredScores.push(scores.data[index])
                    }
                }
            }

            const sortedData = filteredScores.slice().sort((a, b) => parseFloat(b.averageScore) - parseFloat(a.averageScore));

            const rank = sortedData.findIndex((entry) => parseFloat(entry.averageScore) === specificBusinessAverageScore) + 1;

            return rank;
        }
        const rank = findRankByBusinessId(data.data[0].business.id);
        setRank(rank);
    }, [scores]);


    const handleClick = () => {
        history.push('/dashboard/scorecard');
    }

    return (
        <>
            <Dashboard sidebarRoutes={clientRoutes} location={location}>
                <div className={`${styles.back} ${styles.noPrint}`}>
                    <a href="" onClick={handleClick}><FaArrowLeft size="20px" /> Back</a>
                </div>
                <BusinessInfoCard businessName={data.data[0].business.name} status="Active" year={data.data[0].year} quarter={data.data[0].quarter} dateScored={dateScored} />
                <ScoresTable data={data.data[0]} />
                <ScoresAverage averageScore={data.data[0].averageScore} rank={rank} />
                <DownloadScores />
            </Dashboard >
        </>
    );
};

export default ScoreDetail;