const HOMEPAGE = "/";
const SIGNUP_PAGE = "/signup";
const INVITED_PAGE = "/invited";
const SIGNIN_PAGE = "/signin";
const TERMS_CONDITIONS_PAGE = "/terms-conditions";
// const PRIVACY_POLICY_PAGE='/privacy';
const PRIVACY_POLICY_PAGE = "https://www.somoafrica.org/privacy";
const PROFILE_PAGE = "/dashboard/profile";
const DASHBOARD_HOMEPAGE = "/dashboard/overview";
const DASHBOARD_APPLICANT = "/dashboard/overview-applicant";
const CONSUMER_CREDIT_PAGE = "/dashboard/consumer-credit";
const CREDIT_REPORT_PAGE = "/dashboard/credit-report";
const VERIFY_OTP_PAGE = "/verify-otp";
const PRODUCTS_PAGE = "/products";
const ABOUT_US_PAGE = "/about-us";
const CONTACT_PAGE = "/contact-us";
const LOAN_CALCULATOR_PAGE = "/loan-calculator";
const SALES_AGENT_OVERVIEW = "/sales-agent/overview";
const CLIENT_LIST_PAGE = "/sales-agent/clients";
const FAQ_PAGE = "/frequently-asked-questions";
const LOAN_LIST_PAGE = "/sales-agent/loans";
const SUPPORT_PAGE = "/dashboard/support";
const CLIENT_DETAILS_PAGE = "/sales-agent/client/:clientId";
const LOAN_DETAIL_PAGE = "/sales-agent/loan/:loanId";
const PROCESSORS_DASHBOARD = "/processor/overview";
const PROCESSORS_CLIENTS_PAGE = "/processor/clients";
const PROCESSORS_LOANS_PAGE = "/processor/loans";
const FORGOT_PASSWORD_PAGE = "/password/email";
const RESET_PASSWORD_PAGE = "/password/reset";
const RESET_SUCCESS_PAGE = "/password/reset-success";
const PROCESSORS_CLIENT_DETAILS = "/processor/client/:clientId";
const PROCESSORS_LOAN_DETAILS = "/processor/loan/:loanId";
const AUTHORIZER_OVERVIEW = "/authorizer/overview";
const AUTHORIZER_LOANS = "/authorizer/loans";
const AUTHORIZER_CLIENTS = "/authorizer/clients";
const AUTHORIZER_CLIENT_DETAILS = "/authorizer/client/:clientId";
const AUTHORIZER_LOAN_DETAILS = "/authorizer/loan/:loanId";
const ADMIN_OVERVIEW = "/super-admin/overview";
const ADMIN_LOANS = "/super-admin/loans";
const ADMIN_CLIENTS = "/super-admin/clients";
const ADMIN_CLIENT_DETAILS = "/super-admin/client/:clientId";
const ADMIN_LOAN_DETAILS = "/super-admin/loan/:loanId";
const ADMIN_STAFF = "/super-admin/staffs";
const ADMIN_REPORTS = "/super-admin/reports";
const ONBOARDING_PAGE = "/dashboard/onboarding";
const APPLICATION_PAGE = "/dashboard/applicant";
const PLAYBOOK_PAGE = "/dashboard/playbook";
const SCORECARD_PAGE = "/dashboard/scorecard";
const SCOREDETAIL_PAGE = "/dashboard/detailedscores";
const DIGIKUA_PAGE = "/dashboard/digikua/client";
const DIGIKUA_APPLICANT_PAGE = "/dashboard/digikua/applicant";
const SURVEY_PAGE = "/dashboard/qsurvey/client";
const SURVEY_APPLICANT_PAGE = "/dashboard/qsurvey/applicant";
const FIN_VALIDATION = "/dashboard/validation";
const LMS_PAGE = "/dashboard/lms";

const pageUrl = {
  HOMEPAGE,
  SIGNUP_PAGE,
  SIGNIN_PAGE,
  INVITED_PAGE,
  TERMS_CONDITIONS_PAGE,
  PRIVACY_POLICY_PAGE,
  PROFILE_PAGE,
  DASHBOARD_HOMEPAGE,
  DASHBOARD_APPLICANT,
  CONSUMER_CREDIT_PAGE,
  CREDIT_REPORT_PAGE,
  VERIFY_OTP_PAGE,
  PRODUCTS_PAGE,
  ABOUT_US_PAGE,
  CONTACT_PAGE,
  LOAN_CALCULATOR_PAGE,
  SALES_AGENT_OVERVIEW,
  CLIENT_LIST_PAGE,
  FAQ_PAGE,
  LOAN_LIST_PAGE,
  SUPPORT_PAGE,
  CLIENT_DETAILS_PAGE,
  LOAN_DETAIL_PAGE,
  PROCESSORS_DASHBOARD,
  PROCESSORS_CLIENTS_PAGE,
  PROCESSORS_LOANS_PAGE,
  FORGOT_PASSWORD_PAGE,
  RESET_PASSWORD_PAGE,
  RESET_SUCCESS_PAGE,
  PROCESSORS_CLIENT_DETAILS,
  PROCESSORS_LOAN_DETAILS,
  AUTHORIZER_OVERVIEW,
  AUTHORIZER_LOANS,
  AUTHORIZER_CLIENTS,
  AUTHORIZER_CLIENT_DETAILS,
  AUTHORIZER_LOAN_DETAILS,
  ADMIN_OVERVIEW,
  ADMIN_CLIENTS,
  ADMIN_LOANS,
  ADMIN_CLIENT_DETAILS,
  ADMIN_LOAN_DETAILS,
  ADMIN_STAFF,
  ADMIN_REPORTS,
  ONBOARDING_PAGE,
  APPLICATION_PAGE,
  PLAYBOOK_PAGE,
  SCORECARD_PAGE,
  SCOREDETAIL_PAGE,
  DIGIKUA_PAGE,
  DIGIKUA_APPLICANT_PAGE,
  SURVEY_PAGE,
  SURVEY_APPLICANT_PAGE,
  FIN_VALIDATION,
  LMS_PAGE,
};

export default pageUrl;
