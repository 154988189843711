import React, { useContext, useEffect } from "react";
import styles from "./FinValidation.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import { Table } from "react-bootstrap";
import noLoan from "../../assets/no-loan.png";
import welcomeToAcc from "../../assets/welcomeBuruka.png";
import somoLogo from "../../assets/logo12.png";
import somoLogo2 from "../../assets/logo.png";
import { Row, Col, Modal } from "react-bootstrap";
import { Fa500Px, FaAccusoft, FaAmilia, FaArrowCircleLeft, FaArrowRight, FaBatteryEmpty, FaBatteryQuarter, FaBell, FaBellSlash, FaBomb, FaBook, FaBookOpen, FaBookReader, FaBusinessTime, FaCalendarCheck, FaCarBattery, FaChalkboardTeacher, FaCheck, FaCheckCircle, FaCheckDouble, FaCheckSquare, FaCircle, FaCircleNotch, FaClock, FaCloudUploadAlt, FaFileUpload, FaFirstOrder, FaFlagCheckered, FaHome, FaMailBulk, FaMoneyCheckAlt, FaRegArrowAltCircleDown, FaRegBell, FaRocket, FaSms, FaSquare, FaSquareFull, FaSquareRootAlt, FaStickerMule, FaStickyNote, FaStopwatch, FaTicketAlt, FaTimes, FaTimesCircle, FaUserShield, FaWalking, FaWineGlassAlt } from "react-icons/fa";
import { TiCancelOutline } from "react-icons/ti";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
// import Loader from "react-spinners/BounceLoader";
// import Loader1 from "react-spinners/ScaleLoader";
// import Loader2 from "react-spinners/ClimbingBoxLoader";
// import Loader3 from "react-spinners/ClipLoader";
import LoaderPre from "react-spinners/ClockLoader";
// import Loader5 from "react-spinners/RingLoader";
// import Loader6 from "react-spinners/PuffLoader";
// import Loader7 from "react-spinners/RotateLoader";
import LoaderIntroWait from "react-spinners/HashLoader";
import LoaderIntro from "react-spinners/SyncLoader";
// import Loader10 from "react-spinners/PacmanLoader";
// import Loader11 from "react-spinners/PropagateLoader";
// import Loader12 from "react-spinners/PuffLoader";
// import Loader13 from "react-spinners/PulseLoader";
import ApplicantUniUploadForm from "../../components/ApplicantUniUploadForm/ApplicantUniUploadForm";

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import { clientRoutes } from "../../routes/sidebarRoutes";
import _ from "lodash";
import { renderToStaticMarkup } from 'react-dom/server';
import { FadeLoader } from "react-spinners";
import { FaBoxArchive, FaClockRotateLeft, FaDiagramNext, FaListCheck, FaMobileScreen, FaMobileScreenButton, FaScreenpal, FaTachographDigital, FaTemperatureQuarter, FaTicket, FaTicketSimple, FaTimeline, FaUpload } from "react-icons/fa6";
import { count } from "d3-array";

const FinValidation = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const applyStageArray = {
    "/dashboard/applicant/apply/activate-done-at-login": 0,
    "/dashboard/applicant/apply/start": 1,
    "/dashboard/applicant/apply/id_front": 2,
    "/dashboard/applicant/apply/id_back": 3,
    "/dashboard/applicant/apply/kra_pin": 4,
    "/dashboard/applicant/apply/b_name": 5,
    "/dashboard/applicant/apply/b_bank": 6,
    "/dashboard/applicant/apply/invest_overview": 7,
    "/dashboard/applicant/apply/invest_video": 8,
    "/dashboard/applicant/apply/invest_image": 9,
    "/dashboard/applicant/apply/invest_logo": 10,
    "/dashboard/applicant/apply/e_map": 11,
    "/dashboard/applicant/apply/conduct": 12,
    "/dashboard/applicant/apply/photography": 13,
    "/dashboard/applicant/apply/final": 14,
    // "/dashboard/consumer-credit/apply/calculator": 0,
    // "/dashboard/consumer-credit/apply/fund": 1,
    // "/dashboard/consumer-credit/apply/amount": 2,
    // "/dashboard/consumer-credit/apply/bank-info": 3,
  };

  const {
    state: { loans, error, currentLoanId, incomplete, loanApplicationStage },
    retrieveClientLoans,
    clearError,
    loanApply,
    addAddressForLoan,
    addWorkInfoForLoan,
    addBankInfoForLoan,
    clearCompleteState,
  } = useContext(LoanContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    // console.log('psr>>', user);
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetOnboardingState();      
    // });
    var tpl = {
      advisorsList: `<div class="${styles.advisorList} advisor-list-each" pairId="{pairId}">
        ${renderToStaticMarkup(<FaUserShield size="1.2em" color="#cc6328" class={styles.icon} />)}
          <div class="${styles.advisorListInfo}">
            <span>{name}</span>
            <span>${renderToStaticMarkup(<FaBookReader size="21px" color="#cc6328" class={styles.icon} />)} - {playbook}</span>
            <span>{email}</span>
            <span>{phone}</span>
          </div>
          <input type="checkbox" />
          {checkIcon}
        </div>`,
      iconChecked: renderToStaticMarkup(<FaCheckCircle class={(styles.icon + ' ' + styles.iconChecked)} />),
      iconNonChecked: renderToStaticMarkup(<FaCircleNotch class={`${styles.icon} iconNonChecked`} />),
      iconCheckedHover: renderToStaticMarkup(<FaCheckCircle class={`${styles.icon} ${styles.iconChecked} ${styles.iconCheckedHover}`} />),
      loader: renderToStaticMarkup(<LoaderIntro color="#00a5a2" />),
      playbooksList: `<div class="${styles.advisorList} advisor-list-each" playbookId="{playbookId}">
        ${renderToStaticMarkup(<FaBookReader size="1.2em" color="#cc6328" class={styles.icon} />)}
          <div class="${styles.advisorListInfo}">
            <span>{playbook}</span>
            <span>{progress}</span>
            <span>{status}</span>
            <span>${renderToStaticMarkup(<FaUserShield size="21px" color="#cc6328" class={styles.icon} />)} - {email}</span>
          </div>
        </div>`,
      playbookChapterList: `<div class="${styles.advisorList} advisor-list-each" chapterId="{chapterId}">
        ${renderToStaticMarkup(<FaBookOpen size="1.2em" color="#cc6328" class={styles.icon} />)}
          <div class="${styles.advisorListInfo}">
            <span>{name}</span>
            <span>No. {pos}</span>
          </div>
        </div>`,
      playbookSessionList: `<div class="${styles.advisorList} advisor-list-each" sessionId="{sessionId}">
      ${renderToStaticMarkup(<FaBookOpen size="1.2em" color="#cc6328" class={styles.icon} />)}
        <div class="${styles.advisorListInfo}">
          <span>{name}</span>
          <span>No. {pos}</span>
        </div>
      </div>`,
      loaderMain: `<span style=${{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>Loading...&nbsp;${renderToStaticMarkup(<LoaderIntro color="#00a5a2" />)}</span>`,
      sesTtlTtl: `<h3>{pos}.</h3><h3>{ttl}</h3>`,
      sesToolList: `<div class="${styles.toolCheck}">
        <img src="https://invest.somoafrica.org/img/playbook/{img}" />
        <div class="${styles.toolTtl}">
          <p styles="padding-left: 10px;font-weight:bold;color:#CC6328;">{info}</p>
          <a class="subPlaybookListLinks" href="{value}" target="blank">{name}</a>
        </div>
        {statusIcon}
      </div>`,
      sesToolListDone: `<div class="${styles.checkStatus}">
          <p>Completed</p>
          ${renderToStaticMarkup(<FaCheckCircle size="4em" class={styles.icon} />)}
        </div>`,
      sesToolListWait: `<div class="${styles.checkStatus}">
        <p>Pending...</p>
        ${renderToStaticMarkup(<FadeLoader size="4em" class={styles.icon} />)}
      </div>`,
      sesTaskList: `<div class="${styles.task}">
        <div class="${styles.top}">
          <p><b>Topic : </b><span>{topic}</span></p>
          <p><b>Due Date : </b><span>{duedate}</span></p>
          <p><b>Response Date : </b><span>{resdate}</span></p>
        </div>
        <textarea readonly="">{task}</textarea>
        <div class="${styles.bottom}">
          <p class="${styles.file}">
            <b>Files : </b>
            {files}
          </p>
          <button id="{id}" class="start-task-reply-btn {hide}">Reply</button>
        </div>
      </div>`,
      sesListFiles: `<a href="{link}" target="_blank">File {count}</a>`,
      sesFeedList: `<div class="${styles.feed}">
      ${renderToStaticMarkup(<FaUserShield size="5em" color="#cc6328" className={styles.icon} />)}
        <div class="${styles.left}">
          <p>By : {advisor}</p>
          <div class="${styles.msg}">{feed}</div>
          <p class="${styles.file}">
            <b>Files : </b>
            {files}
          </p>
        </div>

      </div>`,
      quizList: `<div class="${styles.quizList} {hide} {opt}" id="{id}">
        <div class="${styles.quizListInfo}">
          <label>
            <p>{quiz}</p>
            <textarea name={name} code="{code}" rows="1" required="1">{val}</textarea>
          </label>
        </div>
      </div>`,
      finInstituteList: `<div class="${styles.quizList} {hide} {opt}" id="{id}">
        <div class="${styles.quizListInfo}">
          <label>
            <p>{quiz}</p>
            <select name={name} required="1">{val}</select>
          </label>
        </div>
      </div>`,
      finSTTupload: `<div class="${styles.quizList} {hide} {opt}" id="{id}">
        <div class="${styles.quizListInfo}">
          <label>
            <p>{quiz}</p>
            <input type='file' name={name} required="1" accept="image/*, .pdf"/>
          </label>
        </div>
      </div>`
    }

    if (document.getElementById('usr_name_salt') && user.firstName) {
      document.getElementById('usr_name_salt').innerText = user.firstName;
    }

    if (document.getElementById('survey_language')) {
      // document.getElementById('usr_name_salt').innerText = user.firstName;
      var qSurvey = (localStorage.getItem('qSurvey') ? JSON.parse(localStorage.getItem('qSurvey')) : null);
      if (!qSurvey) {
        qSurvey = {
          lang: 'en'
        };
        localStorage.setItem('qSurvey', JSON.stringify(qSurvey));
      }
      document.getElementById('survey_language').value = qSurvey.lang;

      document.getElementById('survey_language').addEventListener('change', function () {
        qSurvey.lang = this.value;
        localStorage.setItem('qSurvey', JSON.stringify(qSurvey));
      });
    }

    if (document.getElementById('myChart')) {
      const ctx = document.getElementById('myChart');
      const grpType = ctx.getAttribute('type');
      const Chart = window.Chart;
      var datasets = [{
        label: 'This Month',
        // data: [20, 30, 21, 14, 10, 2, 3],
        data: [Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100)],
        borderWidth: (grpType ? 10 : 0),
        backgroundColor: ['#90292a', '#2cb34a', '#003d4c', '#82ad37', '#215732', '#cdcdcd', '#ff0000'],
        borderColor: '#cc6328'
      }];

      if (grpType) {
        datasets.push({
          label: 'Last Month',
          // data: [20, 30, 21, 14, 10, 2, 3],
          data: [Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100), Math.ceil(Math.random() * 100)],
          borderWidth: (grpType ? 10 : 0),
          backgroundColor: ['#90292a', '#2cb34a', '#003d4c', '#82ad37', '#215732', '#cdcdcd', '#ff0000'],
          borderColor: '#00a5a2'
        })
      }
      try {
        new Chart(ctx, {
          type: (grpType ? grpType : 'pie'),
          animation: true,
          data: {
            labels: ['Equity Bank', 'Mpesa KE', 'KCB Bank', 'Cooperative Bank', 'CRDB TZ', 'Mpesa TZ', 'Discrepancy'],
            datasets: datasets
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: true,
                text: 'Validation Summary Coming Soon...'
              }
            },
            animations: {
              tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: true
              }
            }
          }
        });
      } catch (err) {
        console.log('Canvas Error, Reload-> ', err);
        window.location.reload();
      }
    }

    if (document.getElementById('quizList')) {
      var quizList = document.getElementById('quizList');
      quizList.innerHTML = tpl.loaderMain;

      var surveyLang = 'en';
      var dependents = {};
      var frm = document.forms.fin_validate_form;
      var submitBtn = document.getElementById('surveySubmitBtn');
      var submitLoader = document.getElementById('surveySubmitLoader');

      frm.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        submitLoader.style.display = 'flex';
        quizList.style.display = 'none';
        submitBtn.style.display = 'none';
        var endpoint = atob(this.attributes.endpoint.value);

        var formData = new FormData(this);
        formData.append("phone", user.phoneNumber);
        // console.log(formData);

        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :4.1', y.responseText);
              toast.error("Error Occured " + a.message);
              // quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.message}</div>`;
              submitLoader.style.display = 'none';
              quizList.style.display = 'flex';
              submitBtn.style.display = 'initial';
              return 0;
            } else {
              toast.success("Success " + a.message);
              quizList.innerHTML = `<div class="${styles.no_error}">Success. Await validation report <br/>  ${a.message}</div>`;
              submitLoader.style.display = 'none';
              quizList.style.display = 'flex';
              return 0;
            }

          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error("Error Occured !");
            submitLoader.style.display = 'none';
            quizList.style.display = 'flex';
            submitBtn.style.display = 'initial';
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
          toast.error("Error Occured! Try again");
          submitLoader.style.display = 'none';
          quizList.style.display = 'flex';
          submitBtn.style.display = 'initial';
        };

        y.open('POST', endpoint);
        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send(formData);
      });


      getSurveyQuestions();
      function getSurveyQuestions() {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
              toast.error("Error Occured " + a.error);
              quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.message}</div>`;
              return 0;
            } else if (!a.data || typeof a.data != 'object') {
              console.log('load Error > :3a', y.responseText);
              toast.error("Error Occured " + "No data");
              quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  No data</div>`;
              return 0;
            } else if (!a.count) {
              console.log('load Error > :3b', y.responseText);
              toast.error("Error Occured " + "Invalid data");
              quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/> Invalid data</div>`;
              return 0;
            }

            frm.setAttribute('endpoint', btoa(globalVars.baseUrl5 + globalVars.uploadValidateStt));
            a = a.data;
            var listTpl = '';
            var optTpl = '<option value="" selected="" disabled="">--Select Financial Institution--</option>';
            for (var x in a) {
              optTpl += `<option value="${a[x]['id']}">${a[x]['name']}</option>`;
            }
            listTpl += tpl.finInstituteList.replace(/{quiz}/ig, `Financial Institution.`).replace(/{name}/ig, `financialInsitutionId`).replace(/{val}/ig, optTpl).replace(/{hide}/ig, '').replace(/{id}/ig, '');
            listTpl += tpl.finSTTupload.replace(/{quiz}/ig, `Select Statement(PDF or Image).`).replace(/{name}/ig, `pdfFinancialStatement`).replace(/{val}/ig, 'optTpl').replace(/{hide}/ig, '').replace(/{id}/ig, '');
            // console.log('dependents ->', dependents);

            quizList.innerHTML = (a.length ? listTpl : `<div class="${styles.error}">Could not get financial institutions</div>`);

            var textInputs = quizList.getElementsByTagName('textarea');
            for (var i = 0; i < textInputs.length; i++) {
              textInputs[i].addEventListener('input', function () {
                this.style.height = 'auto';
                this.style.height = ((this.scrollHeight) + 5) + 'px';
                if (dependents[this.attributes.code.value]) {
                  dependents[this.attributes.code.value].forEach((aa) => {
                    // var dpt = dependents[this.attributes.code.value];
                    // console.log('aa', aa);
                    var dpt = aa;
                    var checkVals = dpt.valueIs;
                    // console.log('Has a dependant -> ', dpt);
                    if (typeof dpt.valueIs != 'object') checkVals = [dpt.valueIs];
                    if (checkVals.includes(this.value.trim())) {
                      document.getElementById(dpt.quizId).style.display = 'flex';
                      document.getElementById(dpt.quizId).style.height = '0px';
                      document.getElementById(dpt.quizId).getElementsByTagName('textarea')[0].value = '';
                      setTimeout(() => {
                        document.getElementById(dpt.quizId).style.height = '112px';
                        document.getElementById(dpt.quizId).style.opacity = '1';
                      }, 10);
                      setTimeout(() => {
                        document.getElementById(dpt.quizId).style.height = 'initial';
                        document.getElementById(dpt.quizId).style.overflow = 'initial';
                      }, 270);
                    } else {
                      document.getElementById(dpt.quizId).style.height = document.getElementById(dpt.quizId).scrollHeight + 'px';
                      document.getElementById(dpt.quizId).style.overflow = 'hidden';
                      document.getElementById(dpt.quizId).getElementsByTagName('textarea')[0].value = '---skipped---';
                      setTimeout(() => {
                        document.getElementById(dpt.quizId).style.height = '0px';
                        document.getElementById(dpt.quizId).style.opacity = '0';
                      }, 10);
                      setTimeout(() => {
                        document.getElementById(dpt.quizId).style.display = 'none';
                      }, 270);
                    }
                  });
                }
              });

              textInputs[i].addEventListener('focus', function () {
                this.parentElement.parentElement.parentElement.classList.add(styles.quizListActive);
              });

              textInputs[i].addEventListener('blur', function () {
                this.parentElement.parentElement.parentElement.classList.remove(styles.quizListActive);
              });
            }
            submitBtn.style.display = 'initial';

          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        var reqPayload = {};
        console.log(user);

        y.open('POST', globalVars.baseUrl4 + globalVars.finInstitutes);
        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send(JSON.stringify(reqPayload));
      }
    }
  });

  // useEffect(() => {
  //   retrieveClientLoans();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      clearError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const placeholderFun = (data) => {
    // loanApply(data, user.user_id);
  };

  const calculateLoan = (data) => {
    loanApply(data, user.user_id);
  };

  const updateAddress = (data) => {
    addAddressForLoan(data, currentLoanId);
  };

  const updateEmployerInfo = (data) => {
    addWorkInfoForLoan(data, currentLoanId);
  };

  const updateBankInfo = (data) => {
    addBankInfoForLoan(data, currentLoanId);
  };

  const resumeApplication = () => {
    clearCompleteState();
    if (loanApplicationStage === "calculated") {
      history.push("/dashboard/consumer-credit/apply/contact-info");
    }
    if (loanApplicationStage === "address_added") {
      history.push("/dashboard/consumer-credit/apply/employer-info");
    }
    if (loanApplicationStage === "employer_added") {
      history.push("/dashboard/consumer-credit/apply/bank-info");
    }
  };

  function handlePreSetsForm() {
    var usrLoc = document.getElementById('locationValue').value;
    if (!usrLoc) {
      document.getElementById('preSetsInfo').innerText = 'Select the location of the hub closest to you';
      toast.error('Select the location of the hub closest to you');
      return 0;
    }
    document.getElementById('preSetsLoader').style.display = 'inline';
    document.getElementById('preSetsLoaderWait').style.display = 'flex';
    document.getElementById('preSetsInfo').innerText = '';
    var tmt = setTimeout(function () { window.location.reload(); }, 1000 * 60 * 3);
    var x = new XMLHttpRequest();
    x.onload = function () {
      document.getElementById('preSetsLoader').style.display = 'none';
      document.getElementById('preSetsLoaderWait').style.display = 'none';
      try { clearTimeout(tmt); } catch (er) { console.log(er); }
      try {
        var a = JSON.parse(x.responseText);
        if (!a.status) {
          console.log('load Error > :3', x.responseText);
          toast.error("Error!! " + a.message);
          document.getElementById('preSetsInfo').innerText = (a.error ? a.error : "Error Occured");
        } else {
          document.getElementById('preSetsParent').style.display = 'none';
          window.location.reload();
        }
        console.log('res', a);
      } catch (er) {
        console.log('load Error > :2', x.responseText, er);
        toast.error("Error!! " + er);
        document.getElementById('preSetsInfo').innerText = ("Error Occured" + er);
      }
    };
    x.onerror = function () {
      try { clearTimeout(tmt); } catch (er) { console.log(er); }
      console.log('load Error > :1', x.responseText);
      toast.error('Error! Try again');
      document.getElementById('preSetsInfo').innerText = 'Error! Try again';
      document.getElementById('preSetsLoader').style.display = 'none';
      document.getElementById('preSetsLoaderWait').style.display = 'none';
    };
    x.open('GET', globalVars.baseUrl + globalVars.getApplicantState.replace(':user_id', user.user_id) + '?location=' + usrLoc);
    x.setRequestHeader("Authorization", `Bearer ` + resolveToken());
    x.send();
  }

  return (
    <>
      <Dashboard sidebarRoutes={clientRoutes} location={location}>
        <ToastContainer position="top-center" />
        <div className={styles.heading}>
          <nav>
            {/* <a clicked={() => history.push('/dashboard/playbook/home')}>Hey</a> */}
            <Button bgColor={window.location.href.split("/").pop() == 'home' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/validation/home")}>
              <FaHome /> <span className={styles.btnTxt}>Home</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'start' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/validation/start")}>
              <FaRocket /> <span className={styles.btnTxt}>Start Validation</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'report' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/validation/report")}>
              <FaListCheck /> <span className={styles.btnTxt}>Validation Report</span>
            </Button>
          </nav>
        </div>
        <Switch>
          <Route path={`${path}/home`}>
            <div className={styles.creditTable} ids="mainWelcomePageHolder" id="myEntrepreneursContainer">
              <div className={styles.welcomeAcc}>
                <h2>DigiKua Financial Validation</h2><hr />
                <p>Hi <g id="usr_name_salt">there</g>, <br />
                  Validate your financial records for 100% reporting.<br />
                  <Button
                    bgColor="#cc6328"
                    size="sm"
                    color="#fff"
                    className={`mt-4 ${styles.btn}`}
                    clicked={() =>
                      history.push("/dashboard/validation/start")
                    }
                  >
                    Start Validation <FaRocket size="1.4em" />
                  </Button><br />
                </p>
                <div>
                  <canvas id="myChart" style={{ maxHeight: "490px" }}></canvas>
                </div>
              </div>
            </div>
          </Route>
          <Route path={`${path}/start`}>
            <div className={styles.applicationComplete}>
              <h2><FaRocket size="2em" color="#cc6328" /> Start Validation</h2>
              <p style={{ textAlign: 'left' }}> Select your financial institution and upload the statement<br /></p>
              <form method="POST" name="fin_validate_form" endpoint="">
                <div className={styles.quizListHold} style={{ textAlign: 'left' }} id="quizList" survey_info="quarterly_survey">
                  {/* <div className={styles.quizList}>
                    <div className={styles.quizListInfo}>
                      <label>
                        <p>Select your country<br />1.Kenya<br />2.Tanzania</p>
                        <textarea rows={1} id="autoresizing" name="" required="1"></textarea>
                      </label>
                    </div>
                  </div> */}
                  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                    Loading...&nbsp;<LoaderIntro color="#00a5a2" />
                  </span>

                </div>
                <div className={styles.quizBtnLdr}>
                  <button id="surveySubmitBtn" type="submit" className={styles.surveySubmitBtn}>
                    Submit&nbsp;<FaCloudUploadAlt size="2em" color="#00a5a2" />
                  </button>
                  <span id="surveySubmitLoader" className={styles.surveySubmitLoader} style={{ /*display: 'flex',*/ flexDirection: "column", alignItems: 'center', justifyContent: 'center', minHeight: '100px', fontSize: '2em' }}>
                    Loading...&nbsp;<LoaderIntroWait size="200px" color="#00a5a2" />
                  </span>
                  <br /><br /><br />
                </div>
              </form>
              <p style={{ textAlign: 'left', fontStyle: 'italic' }}>Empowering you, to change the world </p>
            </div>
          </Route>
          <Route path={`${path}/report`}>
            <div className={styles.creditTable}>
              <h2><FaListCheck size="2em" color="#cc6328" /> Validation Report</h2>
              <hr /><h3>Report Coming Soon...</h3>
              <canvas id="myChart" style={{ maxHeight: "4900px" }} type='line'></canvas>
              {/* <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                Loading...&nbsp;<LoaderIntro color="#00a5a2" />
              </span> */}
              <p style={{ textAlign: 'left', fontStyle: 'italic' }}>Empowering you, to change the world </p>
            </div>
          </Route>
          <Route path={`${path} /welcome`}>
            <div className={styles.preSets} id="preSetsParent">
              <div>
                <span id="preSetsLoaderA" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '28px' }}>Loading...&nbsp;<LoaderPre /></span>
                <form id="preSetsForm" style={{ display: 'none' }} onSubmit={(e) => { e.preventDefault(); handlePreSetsForm(); }}>
                  <img src={somoLogo} alt="Somo" />
                  <span id="preSetsInfo"></span>
                  <p>Select your Hub location</p>
                  <label for="proposalUpload">
                    <select id="locationValue" name="location" onChange={(e) => { e.preventDefault(); }}>
                      <option value="" selected={true} disabled={true}>Select Location</option>
                      <option value="Nairobi">Nairobi</option>
                      <option value="Kisumu">Kisumu</option>
                      <option value="Mombasa">Mombasa</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Nakuru">Nakuru</option>
                    </select>
                  </label>
                  <button type="submit" onClick={(e) => { e.preventDefault(); handlePreSetsForm(); }}>
                    Save & Continue
                    <span id="preSetsLoader" style={{ display: 'none' }}><LoaderIntro /></span>
                  </button>
                  <div id="preSetsLoaderWait" className={styles.preSetsWait} style={{ display: 'nones' }}>
                    <LoaderIntroWait />
                    <p>
                      <b>Setting Up Your Somo Space...</b><br />
                      This may take a few minutes (2 to 5 mins)<br />
                      Be patient and do not refresh the page.<br />
                      Leave everything unto us.
                    </p>
                    <img src={somoLogo2} alt="Somo" />
                  </div>
                </form>
              </div>
            </div>
            <div className={styles.creditTable} id="mainWelcomePageHolder">
              <div className={styles.welcomeAcc}>
                <h2>Welcome to Somo Buruka Program</h2>
                <img src={welcomeToAcc} alt="Welcome to Somo Buruka Program" style={{ maxHeight: "250px" }} />
                <hr /><p>Congratulations for making it this far. It's time to submit your application.<br />
                  Here's what you need to submit to complete your application process.
                </p>
                <ol className={styles.steps} id="questionsOverview">
                  <LoaderIntro />
                  {/* <li>Question 1</li> */}
                </ol>
                <i>
                  *
                </i>
                <Button
                  bgColor="#cc6328"
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() =>
                    history.push("/dashboard/applicant/apply/start")
                  }
                >
                  Start Now <FaRocket />
                </Button>
              </div>
            </div>
          </Route>
          <Route path={`${path}/---future---`}>
            <div className={styles.creditTable}>
              {/* <Table striped hover className={styles.tableStyles}>
                <thead>
                  <tr>
                    <th>Loan ID</th>
                    <th>Monthly Repayment</th>
                    <th>Loan Fund</th>
                    <th>Status</th>
                    <th>Requested Amount</th>
                    <th>Balance</th>
                    <th>Approved Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.map((loanInstance, idx) => {
                    let loanBalance = loanInstance?.repayment
                      .filter((repaid) => {
                        return repaid.status === true;
                      })
                      .reduce((acc, curr) => {
                        return curr.scheduledAmount + acc;
                      }, 0);

                    loanBalance = loanInstance?.approvedAmount - loanBalance;

                    let overpayment = loanInstance?.repayment
                      .map((repayment) => repayment.penaltyFee)
                      .reduce((acc, curr) => {
                        return acc + curr;
                      }, 0);

                    return (
                      <tr key={idx}>
                        <td>{loanInstance._id.substring(0, 5)}</td>
                        <td>{`Ksh ${numberWithCommas(
                          (loanInstance.monthlyRepayment ? loanInstance.monthlyRepayment : '--')
                        )}`}</td>
                        <td>
                            {loanInstance.loanFundName}
                        </td>
                        <td>{_.startCase(loanInstance.status)}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.amount)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanBalance)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.approvedAmount)}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {(!loans || loans.length === 0) && (
                <div className={styles.noLoanMessage}>
                  <p>Sorry you currently have no loan</p>
                  <img src={noLoan} alt="No loan history" height="250" />
                  <TiCancelOutline
                    className={styles.mobileNoLoan}
                    size="6em"
                    color="rgba(116, 23, 99, 0.6)"
                  />
                </div>
              )} */}

              <div className={styles.noLoanMessage}>
                <p>Sorry you currently have no loan</p>
                <img src={noLoan} alt="No loan history" height="250" />
                <TiCancelOutline
                  className={styles.mobileNoLoan}
                  size="6em"
                  color="rgba(116, 23, 99, 0.6)"
                />
              </div>
            </div>
          </Route>
          <Route path={`${path}/apply`}>
            <main className={styles.applyContainer} id="mainApplyPageHolder">
              <Row>
                <Col md={4}>
                  <ul className={styles.joinedBullets} id="questionsListSteps">
                    <LoaderIntro />
                  </ul>
                </Col>
                <Col md={8}>
                  <div className={styles.applyForm}>
                    <Switch>
                      <Route path={`${path}/apply/:question`}>
                        <ApplicantUniUploadForm delegateApply={calculateLoan} />
                      </Route>
                    </Switch>
                  </div>
                </Col>
              </Row>
            </main>
          </Route>
          <Route path={`${path}/success`}>
            <div className={styles.applicationComplete}>
              <FaCheckCircle size="4em" color="#cc6328" />
              <h2>Onboarding Complete!</h2>
              <p>
                Your onboarding is complete.
              </p>
              <p><a href="/dashboard/overview" style={{ padding: '7px', display: 'inline-block', border: '2px solid #007bff', margin: '14px auto', borderRadius: '5px' }}>Go to my dashboard</a></p>
            </div>
          </Route>
          <Route path={`${path}/too_late`}>
            <div className={styles.applicationComplete}>
              <FaBomb size="4em" color="#cc6328" />
              <h2>Too Late!</h2>
              <p style={{ textAlign: 'left' }}>
                We're sorry to notify you that your Buruka Submission cannot proceed since the deadline has passed.
              </p>
              <p style={{ textAlign: 'left' }}>For more information: </p>
              <ul style={{ textAlign: 'left' }}>
                <li>reach out to your mentor or trainer </li>
                <li>or send an email to <a href="mailto:trainers@somoafrica.org">Trainers@somoafrica.org</a> </li>
                <li>or visit your nearest <a href="https://www.somoafrica.org/contacts" target="_">Somo Hub.</a></li>
              </ul>
            </div>
          </Route>
          <Route path={`${path}/approvals`}>
            <div className={styles.applicationComplete}>
              <div style={{ position: "relative", width: "64px", margin: "auto" }}>
                <FaStopwatch size="4em" color="#cc6328" />
                <span style={{ position: "absolute", left: "0", right: "0", top: 0, bottom: "1px", transform: "scale(1.4)", display: "flex", justifyContent: "center", alignItems: "flex-end", color: "#000000", border: "2px dotted #cc6328", borderRadius: "50%" }}><LoaderPre color="#ffffff" /></span>
              </div>
              {/* <h2>Awaiting Judging!</h2> */}
              <h2>Submission Successful</h2>
              <p style={{ textAlign: 'left' }}>
                Your submission was successful and advanced to the judging round.<br />
                For now, be calm and just wait.<br />
              </p>
              <p style={{ textAlign: 'left' }}>Empowering you, to change the world </p>
              <ol style={{ textAlign: 'left' }} id="stepsAwaitingApproval">
                {/* <li></li> */}
              </ol>
            </div>
          </Route>
          <Route path={`${path}`}>
            <div className={styles.applicationComplete}>
              <h2>DigiKua Financial Validation</h2><hr />
              <Button
                bgColor="#cc6328"
                size="sm"
                color="#fff"
                className={`mt-4 ${styles.btn}`}
                clicked={() =>
                  history.push("/dashboard/validation/start")
                }
              >
                Start Validation <FaRocket size="1.4em" />
              </Button><br />
            </div>
          </Route>
        </Switch >
      </Dashboard >
      <Modal
        className={styles.continueModal}
        size="sm"
        show={incomplete}
        onHide={() => {
          clearCompleteState();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Continue Application</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have an incomplete loan request, please continue where you left
            off
          </p>
          <Button
            clicked={resumeApplication}
            fullWidth
            className="mt-4"
            bgColor="#cc6328"
            size="sm"
            color="#EBEBEB"
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FinValidation;
