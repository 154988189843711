import React, { useState, useEffect } from "react";
import styles from "./Survey.module.scss";
import { FaBusinessTime, FaCloudUploadAlt } from "react-icons/fa";
import LoaderIntro from "react-spinners/SyncLoader";
import { TiCancelOutline } from "react-icons/ti";
import noLoan from "../../assets/no-loan.png";
import { ToastContainer, toast } from "react-toastify";
import { FaArrowCircleLeft } from "react-icons/fa";
import Button from "../Button/Button";

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";

const Survey = ({
  traineeId,
  cohortId,
  baseUrl,
  survey,
  surveyTitle,
  setSurvey,
  setSurveyTitle,
  round,
}) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const [formValid, setFormValid] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [questions, setQuestions] = useState([]);
  const [submitButton, setSubmitButton] = useState("Submit");

  const getQuestions = async (survey) => {
    try {
      setQuestions([]);
      setLoading(true);
      setError("");
      const url =
        survey === "training-impact-report"
          ? `${baseUrl}surveys/trainees/${survey}`
          : `${baseUrl}surveys/${survey}`;
      const res = await fetch(url);

      if (!res.ok) throw new Error("Something went wrong with fetching survey");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Survey not found");

      setQuestions(data.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSurveySubmit = async (event) => {
    event.preventDefault();

    setSubmitButton(<LoaderIntro color="#00A5A2" />);

    const form = event.currentTarget;
    const formData = new FormData(form);

    const data = {};
    let valid = true;

    formData.forEach((value, key) => {
      const inputType = form.querySelector(`input[name="${key}"]`)?.type;
      if (inputType === "checkbox") {
        if (data[key]) {
          if (Array.isArray(data[key])) {
            data[key].push(value);
          } else {
            data[key] = [data[key], value];
          }
        } else {
          data[key] = [value];
        }
      } else {
        data[key] = value;
      }
    });

    // Check for checkbox validation
    questions.forEach((quiz) => {
      if (quiz.multiple && !data[`Q${quiz.id}`]) {
        valid = false;
      }
    });

    if (!valid) {
      setFormValid(false);
      toast.error("Answer all questions");
      return;
    }

    setFormValid(true);

    const url =
      survey === "business-acumen/entry"
        ? `${baseUrl}surveys/${survey}/submits`
        : survey === "business-acumen/exit"
        ? `${baseUrl}surveys/${survey}/submits`
        : survey === "training-impact-report"
        ? `${baseUrl}surveys/trainees/${survey}/submits`
        : `${baseUrl}surveys/${survey}/${round}/submits`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    console.log(JSON.stringify(data));
    fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error === false) {
          toast.success(data.success);
          setSubmitButton("Submit");

          history.push("/dashboard/lms/home");
          window.location.reload();
        } else {
          toast.error(data.message);
          setSubmitButton("Submit");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setSubmitButton("Submit");
      });
  };

  useEffect(() => {
    const key = location.pathname.split("/survey/")[1];
    const title =
      key === "confidence-level"
        ? "Confidence Survey"
        : key === "training-impact-report"
        ? "Training Impact Report"
        : key === "computer-literacy"
        ? "Google Quiz 1"
        : key === "business-acumen/entry"
        ? "Entry Questions"
        : key === "business-acumen/exit"
        ? "Exit Questions"
        : key === "baseline"
        ? "Baseline Survey"
        : "";
    setSurveyTitle(title);
    setSurvey(key);
  }, []);

  useEffect(() => {
    if (!survey) return;

    getQuestions(survey);
  }, [survey]);

  return (
    <div className={styles.container}>
      <Button
        bgColor="#cc6328"
        size="sm"
        color="#fff"
        className={styles.button}
        clicked={() => {
          setSurvey("");
          setSurveyTitle("");
          history.push("/dashboard/lms/home");
        }}
      >
        <FaArrowCircleLeft />
      </Button>
      <div
        className={styles.applicationComplete}
        style={{ marginBottom: "20px" }}
      >
        <ToastContainer position="top-center" />
        <h2>
          <FaBusinessTime size="2em" color="#cc6328" /> {surveyTitle}
        </h2>
        {!loading && !error && questions.length > 0 && (
          <p style={{ textAlign: "left" }}>
            Answer all questions
            <br />
          </p>
        )}
        {loading && (
          <div>
            <p style={{ textAlign: "left" }}>
              Loading&nbsp;
              <LoaderIntro color="#00a5a2" />
            </p>
          </div>
        )}
        {!loading && error && (
          <>
            <div className={styles.errorQuestions}>
              <TiCancelOutline
                className={styles.mobileNoScores}
                size="5em"
                color="rgba(116, 23, 99, 0.6)"
              />
              <p>{error}</p>
            </div>
          </>
        )}
        {!loading && !error && questions.length < 1 && (
          <>
            <div className={styles.noQuestions}>
              <p>Survey questions have not been added.</p>
              <img src={noLoan} alt="No questions" height="250" />
            </div>
          </>
        )}
        {!loading && !error && questions.length > 0 && (
          <form
            method="POST"
            name="q_survey_form"
            onSubmit={handleSurveySubmit}
          >
            <div
              className={styles.quizListHold}
              style={{ textAlign: "left" }}
              id="quizList"
              survey_info="quarterly_survey"
            >
              <input type="text" name="cohortId" value={cohortId} hidden />
              <input type="text" name="traineeId" value={traineeId} hidden />
              {questions.map((quiz, index) => (
                <div className={styles.quizList} key={index}>
                  <div className={styles.quizListInfo}>
                    <label>
                      <p>
                        {!quiz.name && quiz.options && (
                          <span style={{ color: "#CC6328", border: "none" }}>
                            {quiz.id}
                            {". "}
                          </span>
                        )}
                        {!quiz.name &&
                          !quiz.options &&
                          quiz.input !== false && (
                            <span style={{ color: "#CC6328", border: "none" }}>
                              {quiz.id}
                              {". "}
                            </span>
                          )}

                        {quiz.text}
                        {!quiz.name && quiz.options && quiz.multiple && (
                          <span style={{ fontSize: "0.7rem", border: "none" }}>
                            {" "}
                            (Select all that apply)
                          </span>
                        )}
                      </p>
                      {quiz.imageLink && (
                        <div className={styles.imgContainer}>
                          <img src={quiz.imageLink} alt={quiz.id} />
                        </div>
                      )}
                      {quiz.name && !quiz.options && (
                        <textarea
                          rows={1}
                          id={quiz.name}
                          name={quiz.name}
                          required={quiz.required}
                        ></textarea>
                      )}
                      {quiz.name && quiz.options && (
                        <select
                          id={`${quiz.name}`}
                          name={`${quiz.name}`}
                          required={quiz.required}
                        >
                          <option value="" selected disabled>
                            -- Select --
                          </option>
                          {Object.entries(quiz.options).map(([key, option]) => (
                            <option key={key} value={key}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                      {!quiz.name && !quiz.options && quiz.input !== false && (
                        <textarea
                          rows={1}
                          id={quiz.id}
                          name={`Q${quiz.id}`}
                          required={quiz.required}
                        ></textarea>
                      )}
                      {!quiz.name && quiz.options && !quiz.multiple && (
                        <select
                          id={`Q${quiz.id}`}
                          name={`Q${quiz.id}`}
                          required={quiz.required}
                        >
                          <option value="" selected disabled>
                            -- Select --
                          </option>
                          {Object.entries(quiz.options).map(([key, option]) => (
                            <option key={key} value={key}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                      {!quiz.name && quiz.options && quiz.multiple && (
                        <>
                          {Object.entries(quiz.options).map(([key, option]) => (
                            <div key={key} className={styles.check}>
                              <input
                                type="checkbox"
                                id={key}
                                name={`Q${quiz.id}`}
                                value={key}
                              />
                              <label htmlFor={key}>{option}</label>
                            </div>
                          ))}
                        </>
                      )}
                    </label>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.quizBtnLdr}>
              <button type="submit" className={styles.surveySubmitBtn}>
                {submitButton}&nbsp;
                <FaCloudUploadAlt size="2em" color="#00a5a2" />
              </button>
            </div>
          </form>
        )}
        <br />
      </div>
    </div>
  );
};

export default Survey;
