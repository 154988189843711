import React, { useState, useEffect, useContext } from "react";
import styles from "./LoanFundForm.module.scss";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import { Row, Col } from "react-bootstrap";
import Slider from "react-rangeslider";
import { validateInput } from "../../utils/validateInput";
import { ToastContainer, toast } from "react-toastify";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import {
  convertInput,
  stripCommasInNumber,
} from "../../utils/convertInputType";
import "react-rangeslider/lib/index.css";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
import { Link, useHistory, useLocation } from "react-router-dom";


const LoanFundForm = ({ delegateApply }) => {
  const {
    state: { loading },
    // eslint-disable-next-line no-unused-vars
    loanApply,
  } = useContext(LoanContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { loanFunds },
    retrieveLoanFunds,
  } = useContext(LoanContext);

  const [daysOfMonth, setDaysOfMonth] = useState([]);
  const [limitError, setLimitError] = useState(null);
  const [loanCalcData, setLoanCalcData] = useState({
    monthlySalary: "",
    payDay: null
  });

  const [loanCalcDataErrors, setLoanCalcDataErrors] = useState({
    monthlySalary: null,
    payDay: null,
    loanAmount: null,
    installmentPeriod: null,
    loanPurpose: null,
  });
  const history = useHistory();
  useEffect(() => {
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetLoanState();      
    // });
    checkAndGetLoanState();

    function checkAndGetLoanState() {
      var y = new XMLHttpRequest();
      y.onload = function () {
        try {
          var a = JSON.parse(y.responseText);
          if (a.error) {
            console.log('load Error > :3', y.responseText);
            toast.error(a.message ? a.message : "Error");
          }
          console.log('usr', a.user.user_id, a);
          if (a.user.user_id) {
            getCurrentLoanState(a.user.user_id);
          } else {
            // toast.error(a.message ? a.message : "User read error");
          }
        } catch (er) {
          console.log('load Error > :2', y.responseText, er);
        }
      };
      y.onerror = function () {
        console.log('load Error > :1', y.responseText);
      };
      y.open('GET', globalVars.baseUrl + globalVars.usrUnbox);
      y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      y.send();

      function getCurrentLoanState(usrId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
            }
            console.log('usr', a);
            if (a.data.path) {
              if (window.location.pathname != a.data.path) {
                toast.info("Continue Loan application");
                history.push(a.data.path);
              }
            } else {
            }
          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getLoanState.replace(':user_id', usrId));
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    };
  });

  useEffect(() => {
    setDaysOfMonth(fillUpDaysArray);
    // retrieveLoanFunds();
    var _loan_fund = false, _run_on = false;
    window.addEventListener('DOMContentLoaded', function (e) {/*console.log('>>2>DOM', e);*/ loanFundHandle(); });
    function loanFundHandle() {
      if (_run_on) {
        console.log('Something derailed>>');
        return 0;
      }
      if (!_loan_fund) {
        setTimeout(function () { getLoanFunds(); console.log('Retry after dry run'); }, 5000);
        return 0;
      }
      var frm = document.getElementById('loanFundForm');
      var loanFundLoadDetails = document.getElementById('loanFundLoadDetails');
      var loanFundPartners = document.getElementById('loan-fund-partners');
      var loanFundInfo = document.getElementById('loan-fund-info');
      var loanFundMaxLoan = document.getElementById('loan-fund-maxLoan');
      var loanFundInterest = document.getElementById('loan-fund-interest');
      var loanFundMaxRepayPeriod = document.getElementById('loan-fund-maxRepayPeriod');
      var loanFundRepayInterval = document.getElementById('loan-fund-repayInterval');
      var loanFundBtn = document.getElementById('loan-fund-btn');
      var loadingOff = document.getElementById('loading-off');
      var loadingOn = document.getElementById('loading-on');
      console.log('>>proceed', _loan_fund, frm);
      if (!frm) {
        setTimeout(function () { console.log(`A bit early, let's loop`); }, 1000);
        return 0;
      }
      _run_on = true;
      var new_loan_fund = {};
      var fundOpts = '<option selected="true" disabled="1" value="0">--Select--</option>';
      for (var i = 0; i < _loan_fund.length; i++) {
        new_loan_fund[_loan_fund[i].name] = _loan_fund[i];
        fundOpts += '<option value="' + _loan_fund[i].name + '">' + _loan_fund[i].name + '</option>';
      }
      console.log('>', frm, fundOpts);
      loanFundPartners.innerHTML = fundOpts;
      frm.style.display = 'block';
      document.getElementById('pre-loader').style.display = 'none';

      loanFundPartners.addEventListener('change', function () {
        var x = this.value;
        console.log('>slc>', new_loan_fund[x]);
        loanFundInfo.innerText = new_loan_fund[x].description;
        // loanFundMaxLoan.value = 'Ksh ' + new_loan_fund[x].maxLoan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00';;
        loanFundMaxLoan.value = `${new_loan_fund[x].currency} ` + new_loan_fund[x].maxLoan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00';;
        loanFundInterest.value = new_loan_fund[x].annualInterest;
        loanFundMaxRepayPeriod.value = new_loan_fund[x].maxRepayDurationYears + ' Year' + (new_loan_fund[x].maxRepayDurationYears > 1 ? 's' : '');
        loanFundRepayInterval.value = new_loan_fund[x].repaymentIntervalDays + ' Day' + (new_loan_fund[x].repaymentIntervalDays > 1 ? 's' : '');
        loanFundLoadDetails.style.display = 'block';
      });

      loanFundBtn.addEventListener('click', function () {
        var loanFund = new_loan_fund[loanFundPartners.value];
        var payLoadData = {
          loanFundId: loanFund._id,
          loanFundInterest: loanFund.annualInterest,
          loanFundMaxLoan: loanFund.maxLoan,
          loanFundRepaymentPeriod: loanFund.maxRepayDurationYears,
          loanFundRepaymentInterval: loanFund.repaymentIntervalDays
        };
        if (!loanFundPartners.value) {
          // loadingOff.style.display = 'initial';
          // loadingOn.style.display = 'none';
          toast.error("Select a Somo Loan Partner to continue");
        }
        loadingOff.style.display = 'none';
        loadingOn.style.display = 'initial';
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
              toast.error(a.message ? a.message : "Error");
            }
            console.log('usr', a.user.user_id, a);
            if (a.user.user_id) {
              submitLoanFundDetails(a.user.user_id);
            } else {
              toast.error(a.message ? a.message : "User read error");
            }
          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error(a.message ? a.message : "Response error");
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
          var a = JSON.parse(y.responseText);
          toast.error(a.message ? a.message : "Failed to get user details");
        };
        y.open('GET', globalVars.baseUrl + globalVars.usrUnbox);
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();

        function submitLoanFundDetails(usrId) {
          var y = new XMLHttpRequest();
          y.onload = function () {
            try {
              var a = JSON.parse(y.responseText);
              if (a.error) {
                console.log('load Error > :3', y.responseText);
                handelPropResp(false, y.responseText);
              } else {
                handelPropResp(true, a.data);
              }
            } catch (er) {
              console.log('load Error > :2', y.responseText, er);
              handelPropResp(false, y.responseText);
            }
          };
          y.onerror = function () {
            console.log('load Error > :1', y.responseText);
            handelPropResp(false, y.responseText);
          };
          y.open('POST', globalVars.baseUrl + globalVars.addLoanFundUrl.replace(':user_id', usrId));
          y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
          y.setRequestHeader("Content-Type", "application/json");
          y.send(JSON.stringify(payLoadData));

          function handelPropResp(status, resp) {
            if (!status) {
              console.log('Error>>');
              var err = (typeof resp == 'string' ? JSON.parse(resp) : false);
              resp = err ? err : resp;
              loadingOff.style.display = 'initial';
              loadingOn.style.display = 'none';
              toast.error(resp.error ? resp.error : "Error, Try again");
              return 0;
            } else {
              console.log(resp);
              toast.success(resp.message ? resp.message : "Details updated");
              history.push("/dashboard/consumer-credit/apply/amount");
            }

          }
        }
      });


    }
    function getLoanFunds() {
      if (_loan_fund) {
        console.log('Exit potential Dry run>>', _loan_fund);
        return 0;
      }
      var z = new XMLHttpRequest();
      z.onload = function () {
        // console.log('load>', z.responseText);
        try {
          var a = JSON.parse(z.responseText);
          if (a.error || !a.status) {
            // 
            console.log('load Error > retry :3', z.responseText);
            getLoanFunds();
          }
          _loan_fund = a.data;
          loanFundHandle();
        } catch (er) {
          console.log('load Error > retry :2', z.responseText, er);
          getLoanFunds();
        }
      };
      z.onerror = function () {
        console.log('load Error > retry :1', z.responseText);
        getLoanFunds();
      };
      // z.onreadystatechange = ()=>{console.log('ldn>', z.getAllResponseHeaders());}
      z.open('GET', globalVars.baseUrl + '/loan_fund/list');
      z.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      z.send();
    } getLoanFunds();
  }, []);

  // console.log('frm>1', document.getElementById('loanFundForm'));
  // window.addEventListener('DOMContentLoaded', function(e){console.log('>>1', e);});

  const fillUpDaysArray = () => {
    let daysArray = [];
    for (let i = 0; i < 31; i++) {
      daysArray.push(i + 1);
    }
    return daysArray;
  };

  const {
    monthlySalary,
    loanAmount,
    installmentPeriod,
    payDay,
    loanPurpose,
  } = loanCalcData;

  // useEffect(() => {
  //   if (monthlySalary && loanAmount && installmentPeriod) {
  //     // console.log(typeof monthlySalary, typeof loanAmount, installmentPeriod);
  //     const tenor = Number(installmentPeriod.split(" ")[0]);
  //     let initRate = stripCommasInNumber(loanAmount);
  //     let toRepay = initRate + initRate * 0.04;
  //     toRepay = toRepay + toRepay * 0.04 * tenor;
  //     // for(let i=0; i < tenor; i++) {
  //     //   toRepay = initRate + (initRate * 0.04);
  //     //   initRate = toRepay;
  //     // }
  //     // if (monthlyRepay > percentDti * stripCommasInNumber(monthlySalary)) {
  //     setLoanCalcData({
  //       ...loanCalcData,
  //       estimatedMonthlyPayment: numberWithCommas(Math.floor(monthlyRepay)),
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [monthlySalary, loanAmount, installmentPeriod, loanCalcData.dti]);

  const submitLoanCalcData = () => {
    const fieldsTovalidate = {
      monthlySalary,
      payDay,
      loanAmount,
      installmentPeriod,
      loanPurpose,
    };
    const validated = validateInput(fieldsTovalidate, setLoanCalcDataErrors);
    if (validated) {
      const applyData = {
        monthlySalary: stripCommasInNumber(monthlySalary),
        payDay,
        amount: stripCommasInNumber(loanAmount),
        paymentPeriod: installmentPeriod,
        loanPurpose,
        monthlyRepayment: stripCommasInNumber(
          loanCalcData.estimatedMonthlyPayment
        ),
        DTI: loanCalcData.dti,
      };
      if (stripCommasInNumber(loanAmount) < process.env.REACT_APP_MIN_LOAN) {
        toast.error(
          "Your requested amount is too low. We only offer loans starting from Ksh" + process.env.REACT_APP_MIN_LOAN
        );
      } else if (loanPurpose !== "capital expenses") {
        toast.error(
          "Loans are only available for business growth"
        );
      } else {
        limitError ? toast.error(limitError) : delegateApply(applyData);
      }
    }
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      submitLoanCalcData();
    }
  };

  if (daysOfMonth.length === 0) {
    return null;
  }

  const fundSelectStyle = {
    display: "block",
    width: "100%",
    padding: "10px 7px",
    margin: "5px 0px",
    border: "1px solid #909090",
    borderRadius: "4px",
    background: "#f0f0f0",
    color: "#212121",
    fontSize: "1em"
  }
  const loanFundBtn = {
    width: "100%",
    backgroundColor: "rgb(204, 99, 40)",
    padding: "12px 28px",
    color: "rgb(235, 235, 235)",
    border: "none",
    borderRadius: "4px",
    font: "700 1.2em quicksand, sans-serif"
  };

  return (
    <div>
      <div className={styles.fundForm} id="loanFundForm">
        <ToastContainer position="top-center" />
        <Row className="mb-4">
          <Col className="mb-4 mb-md-0" sm={12} md={12}>
            {/* <InputField
              label="Average monthly revenue?"
              type="text"
              nameAttr="salary"
              value={loanCalcData.monthlySalary}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                const { includesAlphabet, convertedToNumber } = convertInput(val);
                if (!includesAlphabet) {
                  setLoanCalcDataErrors({
                    ...loanCalcDataErrors,
                    monthlySalary: null,
                  });
                  setLoanCalcData({
                    ...loanCalcData,
                    monthlySalary: convertedToNumber.toLocaleString(),
                  });
                }
              }}
              error={
                loanCalcDataErrors.monthlySalary &&
                loanCalcDataErrors.monthlySalary
              }
            /> */}
            <h2>Select Somo Loan Partner</h2>
          </Col>
          <Col sm={12} md={6}>
            {/* <InputField
              label="Budget Day"
              type="select"
              options={daysOfMonth}
              nameAttr="payday"
              value={loanCalcData.payDay}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setLoanCalcDataErrors({ ...loanCalcDataErrors, payDay: null });
                setLoanCalcData({ ...loanCalcData, payDay: val });
              }}
              error={loanCalcDataErrors.payDay && loanCalcDataErrors.payDay}
            /> */}
          </Col>
        </Row>
        <Row className="mb-4">
          {/* <Col>
            <InputField
              label="Somo Loan Partner"
              type="select"
              options={JSON.parse(process.env.REACT_APP_LOAN_PURPOSE)}
              nameAttr="loanPurpose"
              value={loanCalcData.loanPurpose}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setLoanCalcDataErrors({
                  ...loanCalcDataErrors,
                  loanPurpose: null,
                });
                setLoanCalcData({ ...loanCalcData, loanPurpose: val });
              }}
              error={
                loanCalcDataErrors.loanPurpose && loanCalcDataErrors.loanPurpose
              }
            />
          </Col> */}
          <Col>
            <div>
              <label class="fund-label">Select Somo Loan Partner</label>
              <select id="loan-fund-partners" style={fundSelectStyle}>
                <option selected="true" disabled="1" value="0">--Select--</option>
              </select>
            </div>
          </Col>
        </Row>
        <div id="loanFundLoadDetails" style={{ display: "none" }}>
          <Row>
            <Col>
              <p id="loan-fund-info" className={styles.info}></p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="mb-4 mb-md-0" sm={12} md={6}>
              <div className={styles.infoDiv}>
                <label className={styles.infoLbl}>Maximum Loan Amount</label>
                <input id="loan-fund-maxLoan" className={styles.infoInp} readonly="1" disabled="1" />
              </div>
            </Col>
            <Col className="mb-4 mb-md-0" sm={12} md={6}>
              <div className={styles.infoDiv}>
                <label className={styles.infoLbl}>Annual Interest</label>
                <input id="loan-fund-interest" className={styles.infoInp} readonly="1" disabled="1" />
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="mb-4 mb-md-0" sm={12} md={6}>
              <div className={styles.infoDiv}>
                <label className={styles.infoLbl}>Max Repayment Period</label>
                <input id="loan-fund-maxRepayPeriod" className={styles.infoInp} readonly="1" disabled="1" />
              </div>
            </Col>
            <Col className="mb-4 mb-md-0" sm={12} md={6}>
              <div className={styles.infoDiv}>
                <label className={styles.infoLbl}>Repayment Interval</label>
                <input id="loan-fund-repayInterval" className={styles.infoInp} readonly="1" disabled="1" />
              </div>
            </Col>
          </Row>
          {/* <Button
            className="mt-5"
            fullWidth
            clicked={submitLoanCalcData}
            bgColor="#cc6328"
            size="lg"
            color="#EBEBEB"
            disabled={loading}
            loading={loading}
            id="loan-fund-btn"
          >
            Continue
          </Button> */}
          <button class="mt-5" id="loan-fund-btn" style={loanFundBtn}>
            <span id="loading-off">Save & Continue</span>
            <span id="loading-on" style={{ display: "none" }}><Loader /></span>
          </button>
        </div>

      </div>
      <div id="pre-loader" className={styles.pre_ld}> <Loader /> </div>
    </div>
  );
};

export default LoanFundForm;
